<template>
  <div>
   <!-- pricing plan cards -->
    <b-row class="pricing-card" v-if="user.subscription.status != 1 || user.subscription.type == 0">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <!-- <b-col md="6" v-if="user.subscription.status != 1">
            <b-card align="center" >
              <h3>Plan Básico</h3>
              <hr>
              <b-card-text>Genera tus pedidos de manera fácil y rápida</b-card-text>
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{rates.basic}}€</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold"> / mes</sub>
                </div>
              </div>
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  Pedidos ilimitados
                </b-list-group-item>
                <b-list-group-item>
                  Gestión de pedidos automatizadas
                </b-list-group-item>
                <b-list-group-item>
                  Gestión de pedidos automatizadas
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col> -->
          <!-- <b-col :md="user.subscription.status != 1 ? 6 : 12"> -->
          <b-col md="12">
            <b-card
              class="popular"
              align="center"
            >
              <h3>Plan Premium</h3>
              <hr>
              <b-card-text>Aprovecha las mejores ofertas de nuestros proveedores</b-card-text>
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{rates.premium}}€</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold"> / mes</sub>
                </div>
              </div>
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  Pedidos ilimitados
                </b-list-group-item>
                <b-list-group-item>
                  Gestión de pedidos automatizadas
                </b-list-group-item>
                <b-list-group-item>
                  Gestión de pedidos automatizadas
                </b-list-group-item>
                <b-list-group-item class="text-success">
                  <strong>Acceso a las ofertas de nuestros proveedores !!</strong>
                </b-list-group-item>
              </b-list-group>
              <b-button variant="success"  block :to=" {name: 'profile'} " v-if="user.subscription.status == 0">¡Subscribete!</b-button>
              <b-button variant="success"  block :to=" {name: 'profile'} " v-if="user.subscription.status == 1">Mejorar Subscripción</b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="pricing-card" v-if="user.has_subscription && user.subscription.status < 2">
      <b-col cols="12"><h3 class="text-primary mb-2">Mis Datos</h3></b-col>
 
          <b-col md="4">
            <b-card align="center">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="ActivityIcon"/>
              </b-avatar>
              <h3 class="mt-2">Pedidos Realizados</h3>
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{stats[0]}}</span>
                </div>
              </div>
            </b-card>            
          </b-col>
          <b-col md="4">
            <b-card align="center">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="ThumbsUpIcon"/>
              </b-avatar>
              <h3 class="mt-2">Pedidos Aceptados</h3>
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{stats[1]}}</span>
                  </div>
                </div>
            </b-card>            
          </b-col>
          <b-col md="4">
            <b-card align="center">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="GiftIcon"/>
              </b-avatar>
              <h3 class="mt-2">Promociones Activas</h3>
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{stats[2]}}</span>
                  </div>
                </div>
            </b-card>            
          </b-col>
        <b-button
          variant="success"
          block
          :to=" {name: 'order', params: { new: 1 }} "
        >
          Crear Pedido
        </b-button>

     </b-row>
   
    
    <b-card title="¡¡ Se necesita tener una subscripción activa para utilizar la aplicación !!" v-else>
      <b-button
        variant="primary"
        block
        to="/profile"
      >
        Subscribirse
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {BListGroup, BListGroupItem, BRow, BCol, BCard, BCardText, BLink, BButton, BAvatar } from 'bootstrap-vue'
import { mapState } from 'vuex';

export default {
  components: { BRow, BCol, BCard, BListGroup, BListGroupItem, BCardText, BLink, BButton, BAvatar},
  data(){
    return {
      stats : [0,0,0],
      rates : {'basic' : 0 , 'premium' : 0}
    }
  },
  mounted()  {
    this.$http.get("/api/getRates").then((response) => {
      this.rates = response.data;
    }); 
    if(this.user.subscription.status == 1){
      this.$http.get("/api/getStats?id=" + this.user.id).then((response) => {
        this.stats = response.data;
      }); 
    }   
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,   
    }),
  }  
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>